:root {
  --color-dark-theme-background: #1c1b22;
  --color-dark-theme-foreground: #fbfbfe;
  --color-dark-theme-link: #0df;
  --color-light-theme-background: #fff;
  --color-light-theme-foreground: #15141a;
  --color-light-theme-link: #0061e0;
  --color-link: var(--color-light-theme-link);
  --color-main-background: var(--color-light-theme-background);
  --color-main-foreground: var(--color-light-theme-foreground);

  @media (prefers-color-scheme: dark) {
    & {
      --color-link: var(--color-dark-theme-link);
      --color-main-background: var(--color-dark-theme-background);
      --color-main-foreground: var(--color-dark-theme-foreground);
    }
  }
}

html, body {
  background-color: var(--color-main-background);
  color: var(--color-main-foreground);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  margin: 0;
  display: flex;
}

.container {
  max-width: 40em;
  padding: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, system-ui, sans-serif;
  font-size: 1.3rem;
}

.avatar {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-bottom: .5rem;
}

.avatar.is-inverted {
  transform: rotate(180deg);
}

h1 {
  margin-bottom: 1rem;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--color-main-foreground);
}

a:link, a:visited {
  color: var(--color-link);
  text-decoration: underline;
}

a:hover, a:active, a:focus {
  text-decoration: none;
}

p {
  margin-bottom: 1.5rem;
  line-height: 1.3;
}

ul {
  margin-bottom: 1.5rem;
  padding-left: 1rem;
}

ul.bio {
  padding: 0;
  list-style-type: none;
}

li {
  margin-bottom: .5rem;
  line-height: 1.3;
}

small {
  font-size: .9rem;
}

footer {
  margin-top: 2rem;
}

footer p {
  margin-bottom: 0;
}

@keyframes a-slide-up {
  0% {
    opacity: 0;
    transform: translateY(60px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.a-slide-up {
  animation: .6s backwards a-slide-up;
}

h1.a-slide-up {
  animation-delay: .1s;
}

ul.bio.a-slide-up {
  animation-delay: .2s;
}

ul.social.a-slide-up {
  animation-delay: .3s;
}

footer.a-slide-up {
  animation-delay: .4s;
}

@media (prefers-reduced-motion: reduce) {
  .a-slide-up {
    animation: none;
  }
}
/*# sourceMappingURL=alxgbsn.co.uk.6e4ab3aa.css.map */
